import steak_logo from "../assets/steak_logo.png";
import { HEAD_DATA, SUPPORTED_CHAIN_IDS, TOKENS } from "../config/data";
import { useWallet } from "../context/wallet.context";
import styles from "../styles/Faq.module.scss";
import { ArrowIcon, ExternalLinkIcon } from "./Icons";

const Faq = (): JSX.Element => {
    const { chainId } = useWallet();

    const addTokenToWallet = async (address: string) => {
        try {
            if (!window.ethereum) {
                return;
            }
            const rToken = TOKENS[address];
            const wasAdded = await (window.ethereum as any)?.request({
                method: "wallet_watchAsset",
                params: {
                    type: "ERC20",
                    options: {
                        address: rToken.address,
                        symbol: rToken.symbol,
                        decimals: rToken.decimals,
                        image: rToken.img,
                    },
                },
            });

            if (!wasAdded) {
                console.error("Token was not added to wallet");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className={styles.faq}>
            <div className={styles.head}>
                <div className={styles.subhead}>
                    <a className={styles.back} href="/">
                        <i>
                            <ArrowIcon />
                        </i>
                        Back to PawFi
                    </a>
                    {/* <p>Once you unstake, your claims will reset.</p> */}
                </div>
                {/* <div className={styles.points}>Points: {"1,234"}</div> */}
                <div className={styles.utility}>
                    <a
                        href={
                            HEAD_DATA?.[chainId as SUPPORTED_CHAIN_IDS]
                                ?.buy_paw ??
                            HEAD_DATA[SUPPORTED_CHAIN_IDS.MAINNET].buy_paw
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Buy PAW
                        <i>
                            <ExternalLinkIcon />
                        </i>
                    </a>
                    <a
                        href={
                            HEAD_DATA?.[chainId as SUPPORTED_CHAIN_IDS]
                                ?.buy_steak ??
                            HEAD_DATA[SUPPORTED_CHAIN_IDS.MAINNET].buy_steak
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Buy STEAK
                        <i>
                            <ExternalLinkIcon />
                        </i>
                    </a>
                    {chainId === SUPPORTED_CHAIN_IDS.SHIBARIUM ? (
                        <></>
                    ) : (
                        // <button
                        //     onClick={() =>
                        //         addTokenToWallet(
                        //             HEAD_DATA?.[chainId as SUPPORTED_CHAIN_IDS]
                        //                 ?.add_paw ??
                        //                 HEAD_DATA[SUPPORTED_CHAIN_IDS.MAINNET]
                        //                     .add_paw
                        //         )
                        //     }
                        // >
                        //     Add PAW To Wallet
                        //     <i>
                        //         <PlusIcon />
                        //     </i>
                        // </button>
                        <></>
                    )}
                    {/* <button
                        onClick={() =>
                            addTokenToWallet(
                                HEAD_DATA?.[chainId as SUPPORTED_CHAIN_IDS]
                                    ?.add_steak ??
                                    HEAD_DATA[SUPPORTED_CHAIN_IDS.MAINNET]
                                        .add_steak
                            )
                        }
                    >
                        Add STEAK To Wallet
                        <i>
                            <PlusIcon />
                        </i>
                    </button> */}
                </div>
            </div>
            <div className={styles.line} />
            <div className={styles.content}>
                <h1>FAQ</h1>
                <div className={styles.primary}>
                    <h2>Testnet Airdrop Campaign</h2>
                    <p>
                        Participate in the campaign to qualify for a STEAK
                        airdrop
                    </p>
                    <p>
                        You must hold 50,000,000
                        <img
                            src={
                                TOKENS[
                                    "0x1aa51bc7eb181ce48ce626bf62f8956fa9555136"
                                ].img
                            }
                            alt="PAW"
                            loading="lazy"
                        />
                        to be eligible for points.
                    </p>
                    <div className={styles.box}>
                        <h2>Phase One Points</h2>
                        <p>Earn 2 points every time you stake</p>
                        <p>Earn 1 point every time you claim</p>
                        <p>
                            <i>
                                Receive points for up to 10 stakes and 10 claims
                                every day
                            </i>
                        </p>
                    </div>
                    <div className={styles.box}>
                        <h2>Multipliers</h2>
                        <div className={styles.multipliers}>
                            <div className={styles.rowHalf}>
                                <p>2X</p>
                                <img src={steak_logo} alt="STEAK" />
                                <p>10 - 19 STEAK</p>
                            </div>
                            <div className={styles.rowHalf}>
                                <p>7X</p>
                                <img src={steak_logo} alt="STEAK" />
                                <p>60 - 69 STEAK</p>
                            </div>
                            <div className={styles.rowHalf}>
                                <p>3X</p>
                                <img src={steak_logo} alt="STEAK" />
                                <p>20 - 29 STEAK</p>
                            </div>
                            <div className={styles.rowHalf}>
                                <p>8X</p>
                                <img src={steak_logo} alt="STEAK" />
                                <p>70 - 79 STEAK</p>
                            </div>
                            <div className={styles.rowHalf}>
                                <p>4X</p>
                                <img src={steak_logo} alt="STEAK" />
                                <p>30 - 39 STEAK</p>
                            </div>
                            <div className={styles.rowHalf}>
                                <p>9X</p>
                                <img src={steak_logo} alt="STEAK" />
                                <p>80 - 89 STEAK</p>
                            </div>
                            <div className={styles.rowHalf}>
                                <p>5X</p>
                                <img src={steak_logo} alt="STEAK" />
                                <p>40 - 49 STEAK</p>
                            </div>
                            <div className={styles.rowHalf}>
                                <p>10X</p>
                                <img src={steak_logo} alt="STEAK" />
                                <p>90 - 99 STEAK</p>
                            </div>
                            <div className={styles.rowHalf}>
                                <p>6X</p>
                                <img src={steak_logo} alt="STEAK" />
                                <p>50 - 59 STEAK</p>
                            </div>
                            <div className={styles.rowHalf}>
                                <p>12X</p>
                                <img src={steak_logo} alt="STEAK" />
                                <p>100+ STEAK</p>
                            </div>
                        </div>
                    </div>
                    <p>Funds must be in the same wallet</p>
                </div>
                <div className={styles.secondary}>
                    <div>
                        <h4>What network do I have to be on?</h4>
                        <p>
                            The network we support are Ethereum and Shibarium.
                            STEAK rewards are available on both Ethereum and
                            Shibarium.
                        </p>
                    </div>
                    <div>
                        <h4>How do I claim my rewards?</h4>
                        <p>
                            Rewards are in STEAK. Your rewards are claimable on
                            the staking page or the dashboard. You can then
                            click on the “claim” button for the tokens you have
                            staked.
                        </p>
                    </div>
                    <div>
                        <h4>Where do I obtain funds?</h4>
                        <p>
                            You can obtain funds for staking on either the
                            Shibarium or Ethereum network, depending on what
                            network the token is on. You can use any Ethereum or
                            Shibarium exchange to obtain the tokens available on
                            our platform.
                        </p>
                    </div>
                    <div>
                        <h4>
                            When will bridging, lending, and lottery be
                            released?
                        </h4>
                        <p>
                            They will be released in phases starting off with
                            staking. We are aiming to release all features this
                            year.
                        </p>
                    </div>
                    <div>
                        <h4>Can PAW be bridged?</h4>
                        <p>PAW is currently only on the Ethereum network.</p>
                    </div>
                    <div>
                        <h4>
                            How can we list our token on the staking platform?
                        </h4>
                        <p>
                            If you would like your token to be on our platform,
                            contact us at support@pawzone.io.
                        </p>
                    </div>
                </div>
                <a
                    href="mailto:support@pawzone.io"
                    target="_blank"
                    className={styles.contact}
                >
                    Contact us: support@pawzone.io
                </a>
            </div>
        </div>
    );
};

export default Faq;
