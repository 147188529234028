import { useWeb3Modal } from "@web3modal/react";
import axios from "axios";
import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useProvider } from "wagmi";
import {
    HEAD_DATA,
    QUERY_LOGS_BLOCK_LIMITS,
    REFRESH_INTERVAL,
    STAKING_CONTRACTS_MAPPING,
    SUPPORTED_CHAIN_IDS,
    TOKENS,
} from "../config/data";
import { useWallet } from "../context/wallet.context";
import styles from "../styles/Dashboard.module.scss";
import { PawFiStaking } from "../types/contracts";
import { StakingContract, TokenContract } from "../types/staking.types";
import { formatNumber } from "../utils/formatter";
import DashboardBox from "./DashboardBox";
import { ExternalLinkIcon } from "./Icons";

export type Position = {
    stakingContract: {
        config: StakingContract;
        contract: PawFiStaking;
    };
    token: TokenContract;
    staked: string;
    stakedUSD: string;
    sTokenUSDPrice: number;
    steakPriceUSD: number;
    claimable: number;
    apy: string;
};

const Dashboard = (): JSX.Element => {
    const { isConnected, address, chainId, signer, publicProvider } =
        useWallet();
    const sepolia_provider = useProvider({
        chainId: SUPPORTED_CHAIN_IDS.SEPOLIA,
    });
    const mainnet_provider = useProvider({
        chainId: SUPPORTED_CHAIN_IDS.MAINNET,
    });
    const shibarium_provider = useProvider({
        chainId: SUPPORTED_CHAIN_IDS.SHIBARIUM,
    });
    const { open } = useWeb3Modal();

    const [isPositionsLoading, setPositionsLoading] = useState(false);
    const [pawPriceUSD, setPawPriceUSD] = useState(0);
    const [steakPriceUSD, setSteakPriceUSD] = useState(0);
    const [steakPriceUSDLoading, setSteakPriceUSDLoading] = useState(false);
    const [positions, setPositions] = useState<Position[]>([]);
    const [totalClaimable, setTotalClaimable] = useState(0);
    const [totalRewardsWithdrawn, setTotalRewardsWithdrawn] = useState(0);
    const [totalRewardsWithdrawnLoading, setTotalRewardsWithdrawnLoading] =
        useState(false);

    const stakeContracts = useMemo(() => {
        return Object.entries(STAKING_CONTRACTS_MAPPING).reduce(
            (acc, contractConfig) => {
                contractConfig[1].forEach((config) => {
                    if (!publicProvider) return;
                    let _provider = publicProvider;
                    if (config.chainId === SUPPORTED_CHAIN_IDS.SEPOLIA) {
                        _provider = sepolia_provider;
                    } else if (config.chainId === SUPPORTED_CHAIN_IDS.MAINNET) {
                        _provider = mainnet_provider;
                    } else if (
                        config.chainId === SUPPORTED_CHAIN_IDS.SHIBARIUM
                    ) {
                        _provider = shibarium_provider;
                    }
                    acc.push({
                        chainId: config.chainId,
                        config: config,
                        contract: new ethers.Contract(
                            config.address,
                            config.abi,
                            _provider
                        ) as PawFiStaking,
                        provider: _provider,
                    });
                });
                return acc;
            },
            [] as Array<{
                chainId: number;
                config: StakingContract;
                contract: PawFiStaking;
                provider: ethers.providers.Provider;
            }>
        );
    }, [signer, chainId, publicProvider]);

    const totalStakedUSD = useMemo(() => {
        let usdInvalid = false;
        const _totalStakedUSD = positions.reduce((acc, position) => {
            if (isNaN(parseFloat(position.stakedUSD))) {
                usdInvalid = true;
                return acc;
            }
            return acc + parseFloat(position.stakedUSD);
        }, 0.0);
        return usdInvalid ? 0 : _totalStakedUSD;
    }, [positions]);

    const expectedEarning = useMemo(() => {
        if (!steakPriceUSD || positions.length === 0) return 0.0;
        return positions.reduce(
            (acc, position) =>
                acc +
                parseFloat(position.staked) *
                    (parseFloat(position.apy) / (365 * 100)),
            0.0
        );
    }, [positions, steakPriceUSD]);

    const updatePosition = (index: number, position: Position) => {
        const newPositions = [...positions];
        newPositions[index] = position;
        setPositions(newPositions);
    };

    const handleConnect = async () => {
        await open();
    };

    const addTokenToWallet = async (address: string) => {
        try {
            if (!window.ethereum) {
                return;
            }
            const rToken = TOKENS[address];
            const wasAdded = await (window.ethereum as any)?.request({
                method: "wallet_watchAsset",
                params: {
                    type: "ERC20",
                    options: {
                        address: rToken.address,
                        symbol: rToken.symbol,
                        decimals: rToken.decimals,
                        image: rToken.img,
                    },
                },
            });

            if (!wasAdded) {
                console.error("Token was not added to wallet");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchPositions = async () => {
        try {
            if (!address || !steakPriceUSD) return;

            setPositionsLoading(true);
            const positions: Position[] = [];

            for await (const stakingContract of stakeContracts) {
                const staked = await stakingContract.contract.balanceOf(
                    address
                );
                const claimable = await stakingContract.contract.earned(
                    address
                );
                const sToken = stakingContract.config.sToken;
                if (staked.gt(0) || claimable.gt(0)) {
                    const sTokenUSDPriceRes = await axios
                        .get(
                            TOKENS[sToken].coinGeckoId
                                ? `https://api.coingecko.com/api/v3/simple/price?ids=${TOKENS[sToken].coinGeckoId}&vs_currencies=usd&precision=full`
                                : `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${TOKENS[sToken].address}&vs_currencies=usd&precision=full`,
                            {
                                headers: {
                                    "x-cg-demo-api-key":
                                        process.env.REACT_APP_COINGECKO_API_KEY,
                                },
                            }
                        )
                        .catch((error) => {
                            console.error("Error fetching sToken price", error);
                            return { data: {} };
                        });

                    const sTokenUSDPrice =
                        sTokenUSDPriceRes.data[
                            TOKENS[sToken].coinGeckoId ?? TOKENS[sToken].address
                        ]?.usd;

                    const usersStakedRes = await axios
                        .get(`${process.env.REACT_APP_API_URL}/wallet/staked`)
                        .catch((error) => {
                            console.error(
                                "Error fetching users staked count",
                                error
                            );
                            return { data: {} };
                        });

                    const rewardPerToken =
                        await stakingContract.contract.rewardPerToken();

                    const apy =
                        (parseFloat(ethers.utils.formatEther(rewardPerToken)) *
                            100 *
                            365) /
                        usersStakedRes?.data;

                    positions.push({
                        stakingContract: stakingContract,
                        token: TOKENS[sToken],
                        staked: ethers.utils.formatEther(staked),
                        stakedUSD: sTokenUSDPrice
                            ? (
                                  parseFloat(ethers.utils.formatEther(staked)) *
                                  sTokenUSDPrice
                              ).toString()
                            : "0",
                        sTokenUSDPrice: sTokenUSDPrice ?? 0,
                        claimable: parseFloat(
                            ethers.utils.formatEther(claimable)
                        ),
                        apy: apy.toString(),
                        steakPriceUSD,
                    });
                }
            }
            setPositions(positions);
        } catch (error) {
            console.error("Error fetching positions", error);
        }
        setPositionsLoading(false);
    };

    const fetchPawPrice = async () => {
        try {
            const res = await axios.get(
                `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0x1aa51bc7eb181ce48ce626bf62f8956fa9555136&vs_currencies=usd&precision=full`,
                {
                    headers: {
                        "x-cg-demo-api-key":
                            process.env.REACT_APP_COINGECKO_API_KEY,
                    },
                }
            );
            const pawPriceUSD =
                res.data["0x1aa51bc7eb181ce48ce626bf62f8956fa9555136"]?.usd ??
                0;
            setPawPriceUSD(pawPriceUSD);
        } catch (error) {
            console.error("Error fetching Paw price", error);
        }
    };

    const fetchSteakPrice = async () => {
        setSteakPriceUSDLoading(true);
        try {
            const res = await axios.get(
                `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0xC4c244F1dbCA07083feE35220D2169957c275e68&vs_currencies=usd&precision=full`,
                {
                    headers: {
                        "x-cg-demo-api-key":
                            process.env.REACT_APP_COINGECKO_API_KEY,
                    },
                }
            );
            const _steakPriceUSD =
                res.data["0xC4c244F1dbCA07083feE35220D2169957c275e68"]?.usd ??
                (Object.values(res.data)[0] as any).usd ??
                0;
            setSteakPriceUSD(_steakPriceUSD);
        } catch (error) {
            console.error("Error fetching STEAK price", error);
        }
        setSteakPriceUSDLoading(false);
    };

    const fetchClaimable = async () => {
        try {
            if (!address || !stakeContracts) return;
            const _positions = [...positions];
            for await (const _position of _positions) {
                const claimable = await stakeContracts
                    .find(
                        (c) =>
                            c.config.address ===
                                _position.stakingContract.config.address &&
                            c.config.chainId ===
                                _position.stakingContract.config.chainId
                    )
                    ?.contract.earned(address);
                if (claimable) {
                    _position.claimable = parseFloat(
                        ethers.utils.formatEther(claimable)
                    );
                }
            }

            if (_positions.length > 0) {
                setPositions(_positions);
            }
        } catch (error) {
            console.error("Error fetching claimable", error);
        }
    };

    const fetchWithdrawn = async () => {
        setTotalRewardsWithdrawnLoading(true);

        try {
            if (!address || !stakeContracts) return;

            const blockLimit =
                QUERY_LOGS_BLOCK_LIMITS[chainId as SUPPORTED_CHAIN_IDS];

            const fetchPromises = Object.values(stakeContracts).map(
                async (stakeContract) => {
                    const currentBlock =
                        await stakeContract.provider.getBlockNumber();

                    const _rewardsEvents = [];

                    for (
                        let i = stakeContract.config.fromBlock ?? 0;
                        i < currentBlock;
                        i += blockLimit
                    ) {
                        const toBlock = Math.min(
                            i + blockLimit - 1,
                            currentBlock
                        );

                        const events = await stakeContract.contract.queryFilter(
                            stakeContract.contract.filters.WithdrawReward(
                                address
                            ),
                            i,
                            toBlock
                        );
                        _rewardsEvents.push(...events);
                    }
                    return _rewardsEvents;
                }
            );

            const allRewardsEvents = await Promise.all(fetchPromises);

            const flatEvents = allRewardsEvents.flat();

            let _totalRewardsWithdrawn = 0;
            for (const event of flatEvents) {
                _totalRewardsWithdrawn += parseFloat(
                    ethers.utils.formatEther(event.args[1])
                );
            }

            setTotalRewardsWithdrawn(_totalRewardsWithdrawn);
        } catch (error) {
            console.error("Error fetching total rewards withdrawn: ", error);
        } finally {
            setTotalRewardsWithdrawnLoading(false);
        }
    };

    useEffect(() => {
        setTotalClaimable(
            positions.reduce((acc, position) => acc + position.claimable, 0.0)
        );
    }, [positions]);

    useEffect(() => {
        fetchWithdrawn();
    }, [stakeContracts, address]);

    useEffect(() => {
        if (!address) return;
        const interval = setInterval(() => {
            fetchClaimable();
        }, REFRESH_INTERVAL);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        fetchPositions();
    }, [stakeContracts, address, steakPriceUSD]);

    useEffect(() => {
        fetchSteakPrice();
    }, []);

    return (
        <div className={styles.dashboard}>
            <div className={styles.head}>
                <div className={styles.subhead}>
                    <h2>Dashboard</h2>
                    <p>A detailed view of your portfolio.</p>
                </div>
                {/* <div className={styles.points}>Points: {"1234"}</div> */}
                <div className={styles.utility}>
                    <a
                        href={
                            HEAD_DATA?.[chainId as SUPPORTED_CHAIN_IDS]
                                ?.buy_paw ??
                            HEAD_DATA[SUPPORTED_CHAIN_IDS.MAINNET].buy_paw
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Buy PAW
                        <i>
                            <ExternalLinkIcon />
                        </i>
                    </a>
                    <a
                        href={
                            HEAD_DATA?.[chainId as SUPPORTED_CHAIN_IDS]
                                ?.buy_steak ??
                            HEAD_DATA[SUPPORTED_CHAIN_IDS.MAINNET].buy_steak
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Buy STEAK
                        <i>
                            <ExternalLinkIcon />
                        </i>
                    </a>
                    {chainId === SUPPORTED_CHAIN_IDS.SHIBARIUM ? (
                        <></>
                    ) : (
                        // <button
                        //     onClick={() =>
                        //         addTokenToWallet(
                        //             HEAD_DATA?.[chainId as SUPPORTED_CHAIN_IDS]
                        //                 ?.add_paw ??
                        //                 HEAD_DATA[SUPPORTED_CHAIN_IDS.MAINNET]
                        //                     .add_paw
                        //         )
                        //     }
                        // >
                        //     Add PAW To Wallet
                        //     <i>
                        //         <PlusIcon />
                        //     </i>
                        // </button>
                        <></>
                    )}
                    {/* <button
                        onClick={() =>
                            addTokenToWallet(
                                HEAD_DATA?.[chainId as SUPPORTED_CHAIN_IDS]
                                    ?.add_steak ??
                                    HEAD_DATA[SUPPORTED_CHAIN_IDS.MAINNET]
                                        .add_steak
                            )
                        }
                    >
                        Add STEAK To Wallet
                        <i>
                            <PlusIcon />
                        </i>
                    </button> */}
                </div>
            </div>
            <div className={styles.line} />
            {isConnected ? (
                <>
                    <div className={styles.body}>
                        <div className={styles.portfolioBox}>
                            <h4>Expected Earnings</h4>
                            <h1>
                                {!positions || !steakPriceUSD
                                    ? "..."
                                    : formatNumber(expectedEarning, 6)}
                            </h1>
                            <div>
                                <p>STEAK PER DAY</p>
                                <p>
                                    ($
                                    {!expectedEarning || !steakPriceUSD
                                        ? "..."
                                        : formatNumber(
                                              expectedEarning * steakPriceUSD,
                                              6
                                          )}
                                    )
                                </p>
                            </div>
                        </div>
                        <div className={styles.portfolioBox}>
                            <h4>Active Positions</h4>
                            <h1>
                                $
                                {!totalStakedUSD
                                    ? "..."
                                    : formatNumber(totalStakedUSD, 4)}
                            </h1>
                            <p>POSITIONS IN USD</p>
                        </div>
                        <div className={styles.portfolioBox}>
                            <h4>Amount Claimable</h4>
                            <h1>
                                $
                                {!steakPriceUSD || steakPriceUSDLoading
                                    ? "..."
                                    : formatNumber(
                                          totalClaimable * steakPriceUSD,
                                          4
                                      )}
                            </h1>
                            <p>CLAIMABLE IN USD</p>
                        </div>
                        <div className={styles.portfolioBox}>
                            <h4>Total Earnings</h4>
                            <h1>
                                {!steakPriceUSD ||
                                steakPriceUSDLoading ||
                                totalRewardsWithdrawnLoading
                                    ? "..."
                                    : `$${formatNumber(
                                          (totalClaimable +
                                              totalRewardsWithdrawn) *
                                              steakPriceUSD,
                                          6
                                      )}`}
                            </h1>
                            <p>EARNINGS IN USD</p>
                        </div>
                    </div>
                    <div className={styles.table}>
                        <div className={styles.header}>
                            <p>My Positions</p>
                            <p>Current APY</p>
                            <p>Total Staked</p>
                            <p>Amount Claimable</p>
                            <p className={styles.empty}></p>
                        </div>
                        {isPositionsLoading ? (
                            <div className={styles.empty}>
                                <p>Loading...</p>
                            </div>
                        ) : positions.length === 0 ? (
                            <div className={styles.empty}>
                                <p>No active positions</p>
                            </div>
                        ) : (
                            positions.map((position, index) => (
                                <DashboardBox
                                    key={index}
                                    position={position}
                                    steakPriceUSD={steakPriceUSD}
                                />
                            ))
                        )}
                    </div>
                    {/* <div className={styles.box}>
                        <SmallDashboardBox
                            token="SHIB"
                            apy={80}
                            stakes={100000}
                            claimable={5475}
                        />
                        <SmallDashboardBox
                            token="SHIB"
                            apy={80}
                            stakes={100000}
                            claimable={5475}
                        />
                        <SmallDashboardBox
                            token="SHIB"
                            apy={80}
                            stakes={100000}
                            claimable={5475}
                        />
                        <SmallDashboardBox
                            token="SHIB"
                            apy={80}
                            stakes={100000}
                            claimable={5475}
                        />
                        <SmallDashboardBox
                            token="SHIB"
                            apy={80}
                            stakes={100000}
                            claimable={5475}
                        />
                    </div> */}
                </>
            ) : (
                <div className={styles.body}>
                    <div className={styles.buttons}>
                        {isConnected ? (
                            <button
                                onClick={handleConnect}
                                className={styles.connect}
                            >
                                {address?.substring(0, 7) +
                                    "..." +
                                    address?.substring(address.length - 7)}
                            </button>
                        ) : (
                            <button
                                onClick={handleConnect}
                                className={styles.connect}
                            >
                                Connect Wallet to View Dashboard
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
