import { Signer, providers } from "ethers";
import { createContext, useContext, useEffect, useMemo, useRef } from "react";
// import { Id, toast } from "react-toastify";
import {
    Chain,
    useAccount,
    useDisconnect,
    useNetwork,
    useProvider,
    useSigner,
    useSwitchNetwork,
} from "wagmi";
import { chains } from "../config/web3.config";

export type WalletContext = {
    isConnected: boolean;
    disconnectAsync: (() => Promise<void>) | undefined;
    address: string;
    chainId: number;
    publicProvider: providers.Provider | undefined;
    signer: Signer | undefined;
    isSignerLoading: boolean;
    switchNetwork:
        | ((chainId_?: number | undefined) => Promise<Chain>)
        | undefined;
};

const initialContext = {
    isConnected: false,
    disconnectAsync: undefined,
    address: "",
    chainId: chains[0].id,
    publicProvider: undefined,
    signer: undefined,
    isSignerLoading: false,
    switchNetwork: undefined,
};

const walletContext = createContext<WalletContext>(initialContext);

const WalletProvider = ({ children }: any): JSX.Element => {
    const { Provider } = walletContext;
    const { address, isConnected } = useAccount({
        onDisconnect() {
            window.location.reload();
        },
    });
    const { chain } = useNetwork();
    const { disconnectAsync } = useDisconnect();

    const correctChain = useMemo(() => {
        if (chain?.id && chains.map((c) => c.id).includes(chain.id)) {
            return chain;
        }
        return chains[0];
    }, [chain, chains]);

    const { data: signer, isLoading: isSignerLoading } = useSigner({
        chainId: correctChain.id,
    });

    const publicProvider = useProvider({ chainId: correctChain.id });

    const { switchNetworkAsync } = useSwitchNetwork({
        chainId: correctChain.id,
    });

    // const toastId = useRef<null | Id>(null);

    // useEffect(() => {
    //     if (chain?.id && !chains.map((c) => c.id).includes(chain.id)) {
    //         toastId.current = toast.error("You are on the wrong network", {
    //             autoClose: false,
    //             draggable: false,
    //             progress: undefined,
    //             hideProgressBar: true,
    //         });
    //     } else {
    //         if (toastId.current && toast.isActive(toastId.current))
    //             toast.dismiss(toastId.current);
    //     }
    // }, [chain?.id]);

    return (
        <Provider
            value={{
                isConnected,
                disconnectAsync,
                address: address ?? "",
                chainId: chain?.id ?? correctChain.id,
                publicProvider,
                signer:
                    correctChain.id === chain?.id
                        ? signer ?? undefined
                        : undefined,
                isSignerLoading,
                switchNetwork: switchNetworkAsync,
            }}
        >
            {children}
        </Provider>
    );
};

export default WalletProvider;

export const useWallet = () => useContext(walletContext);
