import { Web3Modal } from "@web3modal/react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Footer, Navbar } from "./components";
import { ethereumClient } from "./config/web3.config";
import WalletProvider from "./context/wallet.context";
import { Home } from "./pages";
import "./styles/global.scss";

function App() {
    return (
        <>
            <WalletProvider>
                <Navbar />
                <div className="layoutContainer">
                    <div className="topContainer">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route
                                path="/dashboard"
                                element={<Home dashboard />}
                            />
                            <Route path="/faq" element={<Home faq />} />
                        </Routes>
                    </div>
                </div>
                <Footer />
                <Web3Modal
                    projectId={
                        process.env.REACT_APP_WALLETCONNECT_PROJECT_ID as string
                    }
                    ethereumClient={ethereumClient}
                />
                <ToastContainer theme="dark" position="bottom-right" />
            </WalletProvider>
        </>
    );
}

export default App;
